import { FeaturedContentButton } from '../FeaturedContentButton/FeaturedContentButton';
import './FeaturedContent.scss';

export type TFeaturedContentType =
  | 'map'
  | 'weather-warnings'
  | 'water-temperatures'
  | 'mountain-passes'
  | 'snow-depths';

// The list of visible content types should ideally come from
// a config API populated by data from a CMS so we can toggle
// and reorder this list whenever we want. For now this list
// is just hardcoded and must be manually updated when we
// want to show or hide content based on the season.
const contentTypes: TFeaturedContentType[] = [
  'map',
  'weather-warnings',
  //'water-temperatures',
  'snow-depths',
  'mountain-passes'
];

export function FeaturedContent() {
  return (
    <div className="featured-content">
      {contentTypes.map(contentType => (
        <FeaturedContentButton type={contentType} key={contentType} />
      ))}
    </div>
  );
}
