import yrTime from '@nrk/yr-time';
import { convertSymbolKeyToId } from '@nrk/yr-weather-symbols';
import { IForecastDayInterval } from '../../model/forecast';
import { WeatherSymbol } from '../WeatherSymbol/WeatherSymbol';
import { Temperature } from '../Temperature/Temperature';
import { Text } from '../Text/Text';

interface IProps {
  dayIntervals: IForecastDayInterval[];
  columns: Array<{ time?: string; short: string; long: string }>;
}

export const WeatherLocationListItem__Forecast = (props: IProps) => {
  const { dayIntervals, columns } = props;

  const cells: React.ReactNode[] = [];

  // We may have columns for 10, 11, 12, and 13 December because another location has forecast data
  // starting on 10 December, but this location's day intervals array may start on 11 December.
  // We loop through the columns and find the corresponding matching day interval.
  // If there is no corresponding matching day interval we add an empty cell in that column.
  for (const column of columns) {
    // With v4 of react query, we are getting 'dayIntervals' before column is filled with data.
    // We do not want to continue with the predefined values in column.
    if (column.time === undefined) {
      break;
    }
    const columnTime = yrTime.create(column.time);

    let columnDayInterval: IForecastDayInterval | undefined;
    for (const dayInterval of dayIntervals) {
      if (columnTime.isSame(yrTime.create(dayInterval.start), 'day')) {
        columnDayInterval = dayInterval;
        break;
      }
    }

    if (columnDayInterval) {
      cells.push(
        <div key={column.time} className="weather-location-list-item__forecast-wrapper" role="text">
          <span className="nrk-sr">{column.long}</span>
          <WeatherSymbol id={convertSymbolKeyToId(columnDayInterval.twentyFourHourSymbol)} />
          <Text size="4" tabularNums={true}>
            <Temperature value={columnDayInterval.temperature.value} decimal={false} />
          </Text>
        </div>
      );
    } else {
      cells.push(
        <div
          key={column.time}
          className="weather-location-list-item__forecast-wrapper weather-location-list-item__forecast-wrapper--empty"
        />
      );
    }
  }

  return <div className="weather-location-list-item__forecast">{cells}</div>;
};
